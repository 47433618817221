import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'normalize.css';
import 'notyf/notyf.min.css';
import React from 'react';
import { render } from 'react-dom';

import { FIREBASE_CONFIG } from './constants';
import { Root } from './containers/Root';

firebase.initializeApp(FIREBASE_CONFIG);

render((
  <Root/>
), document.getElementById('root'));
