import { faSignOut, faTaxi, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import { useRouter } from '../hooks/useRouter';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

import { SidebarItem } from './SidebarItem';

export function Sidebar() {
  const { history } = useRouter();

  function logOut() {
    firebase.auth().signOut().then(() => history.push('/login'));
  }

  return (
    <StyledSidebar>
      <StyledBrand>
        <StyledLogo icon={faTaxi} size="2x"/>
        Taxi Haarlem Service
      </StyledBrand>
      <SidebarItem icon={faUser} path="/users">
        Gebruikers
      </SidebarItem>
      <StyledSpacer/>
      <SidebarItem icon={faSignOut} onClick={logOut}>
        Uitloggen
      </SidebarItem>
    </StyledSidebar>
  );
}

const StyledSidebar = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 4rem;
  height: 100vh;
  padding: .25rem;
  background-color: #24292e;
  transition: width .5s ease;
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    width: 15rem;
  `)};
`;

const StyledBrand = styled.div`
  display: flex;
  align-items: center;
  width: 2.25rem;
  margin: 1.75rem auto 2rem;
  color: white;
  overflow: hidden;
  transition: width .5s ease;
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    width: 7.5rem;
  `)};
`;

const StyledLogo = styled(FontAwesomeIcon)`
  margin-right: 1.5rem;
`;

const StyledSpacer = styled.div`
  flex: 1;
`;
