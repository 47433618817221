import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { useField } from 'formik';
import React, { SelectHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

import { toUrl } from '../utils/toUrl';

type Props = {
  name: string
};

export function Select({ name, ...props }: Props & SelectHTMLAttributes<HTMLSelectElement>) {
  const [field] = useField(name);

  return (
    <StyledSelect name={name} {...props} {...field}/>
  );
}

const StyledSelect = styled.select`
  display: block;
  width: 100%;
  margin-bottom: .5rem;
  padding: .5rem .75rem;
  background-color: #eeeeef;
  background-image: url("${toUrl(faAngleDown)}");
  background-repeat: no-repeat;
  background-position: calc(100% - .75rem);
  background-size: .75rem;
  border: none;
  border-radius: .5rem;
  appearance: none;
`;
