import React, { ReactNode } from 'react';
import { boundary, useError } from 'react-boundary';
import styled, { css } from 'styled-components/macro';

import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

import { Heading } from './Heading';

type Props = {
  children?: ReactNode,
  title?: string
};

export const Content = boundary(({ children, title }: Props) => {
  const [error] = useError();

  return (
    <StyledContent>
      {title && (
        <StyledHeader>
          <Heading type="h1">
            {title}
          </Heading>
        </StyledHeader>
      )}
      {error ? (
        <StyledError>
          <Heading type="h1">
            Oh no
          </Heading>
          <Heading type="h3">
            Something went wrong
          </Heading>
        </StyledError>
      ) : (
        <StyledInner>
          {children}
        </StyledInner>
      )}
    </StyledContent>
  );
});

const StyledContent = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 4rem;
  transition: margin-left .5s ease;
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-left: 15rem;
  `)};
`;

const StyledHeader = styled.div`
  padding: 2.5rem 1rem 2rem;
  background-color: whitesmoke;
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem;
`;

const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
