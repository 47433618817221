import { FormikErrors, FormikHelpers, FormikProvider, useFormik } from 'formik';
import React from 'react';

type Props<T> = {
  initialValues: T,
  onSubmit: (values: T, helpers: FormikHelpers<T>) => void,
  validate?: (values: T) => void | FormikErrors<T>
};

type BuiltinProps = Omit<JSX.IntrinsicElements['form'], 'onSubmit'>;

export function Form<T>({ initialValues, onSubmit, validate, ...props }: Props<T> & BuiltinProps) {
  const formik = useFormik<T>({
    initialValues,
    onSubmit,
    validate
  });

  return (
    <FormikProvider value={formik}>
      <form {...props} onSubmit={formik.handleSubmit}/>
    </FormikProvider>
  );
}
