import firebase from 'firebase/app';
import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Redirect } from 'react-router';
import styled, { css } from 'styled-components/macro';

import { Reservation as ReservationType } from '../api/Reservation';
import { Button } from '../components/Button';
import { Column } from '../components/Column';
import { Content } from '../components/Content';
import { Form } from '../components/Form';
import { Input } from '../components/Input';
import { Row } from '../components/Row';
import { Select } from '../components/Select';
import { API_URL, notyf } from '../constants';
import { useRouter } from '../hooks/useRouter';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

import { Loader } from './Loading';

type Params = {
  id: string,
  reservation: string
};

export function Reservation() {
  const { history, match: { params: { id, reservation } } } = useRouter<Params>();
  const [res, loading, error] = useDocumentData<ReservationType>(
    firebase.firestore()
      .collection('users')
      .doc(id)
      .collection('reservations')
      .doc(reservation),
    {
      idField: 'id'
    }
  );

  if (error) {
    return (
      <Redirect to="/"/>
    );
  }

  async function saveData(user: ReservationType) {
    const { id: _, ...data } = user;

    try {
      await firebase.firestore()
        .collection('users')
        .doc(id)
        .collection('reservations')
        .doc(reservation)
        .update(data);

      notyf.success('Reservering bijgewerkt');
    } catch (e) {
      notyf.error('Er is iets mis gegaan');
    }
  }

  async function cancel() {
    if (!res || !window.confirm('Weet je zeker dat je deze reservering wilt annuleren?')) {
      return;
    }

    try {
      await fetch(`${API_URL}/${res.id}`, {
        method: 'delete'
      });

      notyf.success('Reservering geannuleerd');

      history.push(`/users/${id}`);
    } catch (e) {
      notyf.error('Er is iets mis gegaan');
    }
  }

  return loading || !res ? (
    <Loader/>
  ) : (
    <Content title="Reservering">
      <Row spacing={1}>
        <Column breakpoints={{ [Breakpoint.Desktop]: 6 }}>
          <Form<ReservationType> initialValues={res} onSubmit={saveData}>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="address.street">Straat*</StyledLabel>
                <Input name="address.street" required={true}/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="address.houseNumber">Huisnummer*</StyledLabel>
                <Input name="address.houseNumber" required={true}/>
              </Column>
            </Row>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="address.zipCode">Postcode</StyledLabel>
                <Input name="address.zipCode"/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="address.city">Woonplaats*</StyledLabel>
                <Input name="address.city" required={true}/>
              </Column>
            </Row>
            <StyledLabel htmlFor="passengers">Aantal personen*</StyledLabel>
            <Input name="passengers" type="number" disabled={res.isCanceled} required={true}/>
            <StyledLabel htmlFor="passengers">Voertuig*</StyledLabel>
            <Select name="carType" disabled={res.isCanceled} required={true}>
              <option value="auto">Auto</option>
              <option value="stationcar">Stationcar</option>
              <option value="bus">Bus</option>
              <option value="">Geen</option>
            </Select>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="date">Datum*</StyledLabel>
                <Input name="date" disabled={res.isCanceled} required={true}/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="time">Tijd*</StyledLabel>
                <Input name="time" disabled={res.isCanceled} required={true}/>
              </Column>
            </Row>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="flightNumber">Vlucht</StyledLabel>
                <Input name="flightNumber" disabled={res.isCanceled}/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="suitCases">Koffers</StyledLabel>
                <Input name="suitCases" disabled={res.isCanceled}/>
              </Column>
            </Row>
            <StyledLabel htmlFor="notes">Notitie</StyledLabel>
            <Input name="notes" disabled={res.isCanceled}/>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="reTour.date">Retourdatum</StyledLabel>
                <Input name="reTour.date" disabled={res.isCanceled}/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="reTour.time">Retourtijd</StyledLabel>
                <Input name="reTour.time" disabled={res.isCanceled}/>
              </Column>
            </Row>
            <StyledSubmit type="submit" disabled={res.isCanceled}>
              Opslaan
            </StyledSubmit>
            <StyledCancel type="button" disabled={res.isCanceled} onClick={cancel}>
              Annuleren
            </StyledCancel>
          </Form>
        </Column>
      </Row>
    </Content>
  );
}

const StyledLabel = styled.label`
  display: block;
  padding: .5rem .75rem;
  opacity: .8;
  font-size: .8rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledSubmit = styled(Button)`
  width: 100%;
  margin-top: .5rem;
  float: right;
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    width: 12rem;
  `)};
`;

const StyledCancel = styled(StyledSubmit)`
  background-color: red;
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    margin-right: 1rem;
  `)};
`;
