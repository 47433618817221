import { useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

type Props = {
  name: string
};

export function Input({ name, ...props }: Props & InputHTMLAttributes<HTMLInputElement>) {
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <StyledWrapper error={error || ''}>
      <StyledInput name={name} size={1} {...props} {...field}/>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ error: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
  ${(props) => props.error && css`
    background-color: red;
    border-radius: .5rem;
    box-shadow: 0 0 0 2px red;
    ::after {
      content: "${props.error}";
      padding: .25rem .5rem;
      color: white;
      text-align: center;
    }
  `};
`;

const StyledInput = styled.input`
  padding: .5rem .75rem;
  background-color: #eeeeef;
  border: none;
  border-radius: .5rem;
`;
