import firebase from 'firebase/app';
import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { User } from '../api/User';
import { Content } from '../components/Content';
import { Table } from '../components/Table';
import { useRouter } from '../hooks/useRouter';
import { compare } from '../utils/compare';

import { Loader } from './Loading';

export function Users() {
  const { history } = useRouter();
  const [users, loading] = useCollectionData<User>(
    firebase.firestore()
      .collection('users'),
    {
      idField: 'id'
    }
  );

  return loading ? (
    <Loader/>
  ) : (
    <Content title="Gebruikers">
      <Table<User>
        onClick={({ id }) => history.push(`/users/${id}`)}
        rows={users || []}
        columns={{
          firstName: [{
            heading: 'Voornaam',
            sort: (a, b) => compare(a, b)
          }],
          lastName: [{
            heading: 'Achternaam',
            sort: (a, b) => compare(a, b)
          }],
          phoneNumber: [{
            heading: 'Telefoonnummer'
          }]
        }}
      />
    </Content>
  );
}
