import { faPlaneArrival, faPlaneDeparture, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compareAsc, format, parse } from 'date-fns';
import firebase from 'firebase/app';
import React from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { Redirect } from 'react-router';
import styled from 'styled-components/macro';

import { Reservation } from '../api/Reservation';
import { User as UserType } from '../api/User';
import { Button } from '../components/Button';
import { Column } from '../components/Column';
import { Content } from '../components/Content';
import { Form } from '../components/Form';
import { Heading } from '../components/Heading';
import { Input } from '../components/Input';
import { Row } from '../components/Row';
import { Table } from '../components/Table';
import { notyf } from '../constants';
import { useRouter } from '../hooks/useRouter';
import { Breakpoint } from '../types';
import { compare } from '../utils/compare';

import { Loader } from './Loading';

type Params = {
  id: string
};

export function User() {
  const { history, match: { params: { id } } } = useRouter<Params>();
  const [user, loading0, error0] = useDocumentData<UserType>(
    firebase.firestore()
      .collection('users')
      .doc(id),
    {
      idField: 'id'
    }
  );
  const [reservations, loading1, error1] = useCollectionData<Reservation>(
    firebase.firestore()
      .collection('users')
      .doc(id)
      .collection('reservations'),
    {
      idField: 'id'
    }
  );

  if (error0 || error1) {
    return (
      <Redirect to="/"/>
    );
  }

  async function saveData(user: UserType) {
    const { id, ...data } = user;

    try {
      await firebase.firestore()
        .collection('users')
        .doc(id)
        .update(data);

      notyf.success('Gebruiker bijgewerkt');
    } catch (e) {
      notyf.error('Er is iets mis gegaan');
    }
  }

  function parseDate(date: string) {
    return parse(date, 'd-L-yyyy', new Date());
  }

  return loading0 || loading1 || !user || !reservations ? (
    <Loader/>
  ) : (
    <Content title={`${user.firstName} ${user.lastName}`}>
      <Row spacing={1}>
        <Column breakpoints={{ [Breakpoint.Desktop]: 6 }}>
          <Heading type="h2">
            Gegevens
          </Heading>
          <Form<UserType> initialValues={user} onSubmit={saveData}>
            <StyledLabel htmlFor="firstName">Voornaam*</StyledLabel>
            <Input name="firstName" required={true}/>
            <StyledLabel htmlFor="lastName">Achternaam*</StyledLabel>
            <Input name="lastName" required={true}/>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="street">Straat*</StyledLabel>
                <Input name="street" required={true}/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="houseNumber">Huisnummer*</StyledLabel>
                <Input name="houseNumber" required={true}/>
              </Column>
            </Row>
            <Row spacing={1}>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="zipCode">Postcode*</StyledLabel>
                <Input name="zipCode" required={true}/>
              </Column>
              <Column breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
                <StyledLabel htmlFor="city">Woonplaats*</StyledLabel>
                <Input name="city" required={true}/>
              </Column>
            </Row>
            <StyledLabel htmlFor="phoneNumber">Telefoonnummer*</StyledLabel>
            <Input name="phoneNumber" required={true}/>
            <StyledSubmit type="submit">
              Opslaan
            </StyledSubmit>
          </Form>
        </Column>
        <Column breakpoints={{ [Breakpoint.Desktop]: 6 }}>
          <Heading type="h2">
            Reserveringen
          </Heading>
          <Table<Reservation>
            onClick={(value) => history.push(`/users/${id}/${value.id}`)}
            rows={reservations}
            columns={{
              fromSchiphol: [{
                heading: '',
                render: (value, { isCanceled }) => (
                  <FontAwesomeIcon icon={isCanceled ? faTimes : value ? faPlaneArrival : faPlaneDeparture}/>
                )
              }],
              date: [{
                heading: 'Datum',
                sort: (a, b) => compareAsc(parseDate(a), parseDate(b)),
                render: (value, row) => {
                  return (
                    <span>
                      {format(parseDate(value), 'PP')} {row.time}
                    </span>
                  );
                }
              }],
              passengers: [{
                heading: 'Aantal personen',
                sort: (a, b) => compare(a, b)
              }]
            }}
          />
        </Column>
      </Row>
    </Content>
  );
}

const StyledLabel = styled.label`
  display: block;
  padding: .5rem .75rem;
  opacity: .8;
  font-size: .8rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledSubmit = styled(Button)`
  width: 12rem;
  margin-top: .5rem;
  float: right;
`;
