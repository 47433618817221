import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

import { Breakpoints } from '../types';
import { forBreakpoints } from '../utils/forBreakpoints';

type Props = {
  breakpoints: Breakpoints<number>
};

export function Column(props: Props & HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledColumn {...props}/>
  );
}

const StyledColumn = styled.div<{ breakpoints: Breakpoints<number> }>`
  flex: 0 0 100%;
  ${(props) => forBreakpoints(props.breakpoints, (value) => css`
    flex-basis: ${100 / 12 * value}%;
  `)};
`;
