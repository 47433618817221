import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

export function Loader() {
  return (
    <StyledLoader>
      <FontAwesomeIcon icon={faCog} size="2x" spin={true}/>
    </StyledLoader>
  );
}

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
