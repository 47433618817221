import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components/macro';

import { PrivateRoute } from '../components/PrivateRoute';
import { Sidebar } from '../components/Sidebar';

import { Login } from './Login';
import { Reservation } from './Reservation';
import { User } from './User';
import { Users } from './Users';

export function Root() {
  return (
    <>
      <StyledGlobal/>
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact={true} component={Login}/>
          <PrivateRoute path="/">
            <Sidebar/>
            <Switch>
              <Route path="/users" exact={true} component={Users}/>
              <Route path="/users/:id" exact={true} component={User}/>
              <Route path="/users/:id/:reservation" exact={true} component={Reservation}/>
              <Route path="/" exact={true} component={() => <Redirect to="/users"/>}/>
            </Switch>
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </>
  );
}

const StyledGlobal = createGlobalStyle`
  html {
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  *:focus {
    outline: none;
    box-shadow: 0 0 0 2px inset gold;
  }
  body {
    overflow-y: scroll;
  }
  button {
    border: none;
    background: none;
  }
  .notyf__toast {
    box-shadow: none;
    border-radius: .5rem;
  }
`;
