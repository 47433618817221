import { css, FlattenSimpleInterpolation } from 'styled-components/macro';

import { BREAKPOINTS } from '../constants';
import { Breakpoint } from '../types';

export function forBreakpoint(breakpoint: Breakpoint, style: FlattenSimpleInterpolation) {
  if (breakpoint === Breakpoint.Phone) {
    return style;
  }

  return css`
    @media (min-width: ${BREAKPOINTS[breakpoint]}rem) {
      ${style};
    }
  `;
}
