import firebase from 'firebase/app';
import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect, Route, RouteProps } from 'react-router';

import { Loader } from '../containers/Loading';

export function PrivateRoute({ children, ...props }: RouteProps) {
  const [user, loading] = useAuthState(firebase.auth());

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  if (loading) {
    return (
      <Loader/>
    );
  }

  return (
    <Route {...props}>
      {user ? children : (
        <Redirect to="/login"/>
      )}
    </Route>
  );
}
