import { css, FlattenSimpleInterpolation } from 'styled-components/macro';

import { Breakpoint, Breakpoints } from '../types';

import { forBreakpoint } from './forBreakpoint';

export function forBreakpoints<T>(
  breakpoints: Breakpoints<T>,
  getStyle: (value: T, breakpoint: Breakpoint) => FlattenSimpleInterpolation
) {
  let result: FlattenSimpleInterpolation | undefined;

  for (const key in breakpoints) {
    if (breakpoints.hasOwnProperty(key)) {
      const breakpoint = Number(key) as Breakpoint;
      const value = breakpoints[breakpoint];

      if (value !== undefined) {
        result = css`
          ${result && result};
          ${forBreakpoint(breakpoint, getStyle(value, breakpoint))};
        `;
      }
    }
  }

  return result;
}
