import { Notyf } from 'notyf';

import { Breakpoint } from './types';

export const notyf = new Notyf();

export const API_URL = 'https://europe-west1-taxi-haarlem-service.cloudfunctions.net/calendar/calendar';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCSLIbmakSZQEmA_xbAWOV03aIocI2nuVQ',
  authDomain: 'taxi-haarlem-service.firebaseapp.com',
  databaseURL: 'https://taxi-haarlem-service.firebaseio.com',
  projectId: 'taxi-haarlem-service',
  storageBucket: 'taxi-haarlem-service.appspot.com',
  messagingSenderId: '82827623016',
  appId: '1:82827623016:web:56765627eefb5929'
};

export const BREAKPOINTS: Record<Breakpoint, number> = {
  [Breakpoint.Phone]: 0,
  [Breakpoint.TabletPortrait]: 37.5,
  [Breakpoint.TabletLandscape]: 56.25,
  [Breakpoint.Desktop]: 75
};
