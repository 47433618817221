import { faTaxi } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router';
import styled from 'styled-components/macro';

import { Button } from '../components/Button';
import { Form } from '../components/Form';
import { Input } from '../components/Input';

import { Loader } from './Loading';

type Values = {
  email: string,
  password: string
};

export function Login() {
  const [user, loading] = useAuthState(firebase.auth());

  async function login({ email, password }: Values, { setFieldError }: FormikHelpers<Values>) {
    try {
      const credentials = await firebase.auth().signInWithEmailAndPassword(email, password);

      if (!credentials.user) {
        return;
      }

      const document = await firebase.firestore()
        .collection('users')
        .doc(credentials.user.uid)
        .get();
      const data = await document.data();

      if (data && data.role === 'admin') {
        return;
      }

      await firebase.auth().signOut();
    } catch (e) {
      setFieldError('password', e.message);
    }
  }

  return loading ? (
    <Loader/>
  ) : user ? (
    <Redirect to="/"/>
  ) : (
    <StyledWrapper>
      <StyledBox>
        <StyledBrand>
          <FontAwesomeIcon icon={faTaxi} size="2x"/>
        </StyledBrand>
        <Form<Values> initialValues={{ email: '', password: '' }} onSubmit={login}>
          <StyledLabel htmlFor="email">Email</StyledLabel>
          <Input name="email" type="email" required={true}/>
          <StyledLabel htmlFor="password">Wachtwoord</StyledLabel>
          <Input name="password" type="password" required={true}/>
          <StyledSubmit type="submit">
            Inloggen
          </StyledSubmit>
        </Form>
      </StyledBox>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #24292e;
`;

const StyledBox = styled.div`
  width: 20rem;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
`;

const StyledBrand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
`;

const StyledLabel = styled.label`
  display: block;
  padding: .5rem .75rem;
  opacity: .8;
  font-size: .8rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledSubmit = styled(Button)`
  width: 100%;
  margin-top: .5rem;
`;
